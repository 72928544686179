// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/raizel/dev/gatsby-default-starter/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-education-js": () => import("/Users/raizel/dev/gatsby-default-starter/src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-entertainment-js": () => import("/Users/raizel/dev/gatsby-default-starter/src/pages/entertainment.js" /* webpackChunkName: "component---src-pages-entertainment-js" */),
  "component---src-pages-film-js": () => import("/Users/raizel/dev/gatsby-default-starter/src/pages/film.js" /* webpackChunkName: "component---src-pages-film-js" */),
  "component---src-pages-gaming-js": () => import("/Users/raizel/dev/gatsby-default-starter/src/pages/gaming.js" /* webpackChunkName: "component---src-pages-gaming-js" */),
  "component---src-pages-howto-js": () => import("/Users/raizel/dev/gatsby-default-starter/src/pages/howto.js" /* webpackChunkName: "component---src-pages-howto-js" */),
  "component---src-pages-index-js": () => import("/Users/raizel/dev/gatsby-default-starter/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/Users/raizel/dev/gatsby-default-starter/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-nonprofit-js": () => import("/Users/raizel/dev/gatsby-default-starter/src/pages/nonprofit.js" /* webpackChunkName: "component---src-pages-nonprofit-js" */),
  "component---src-pages-people-js": () => import("/Users/raizel/dev/gatsby-default-starter/src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-select-router-js": () => import("/Users/raizel/dev/gatsby-default-starter/src/pages/selectRouter.js" /* webpackChunkName: "component---src-pages-select-router-js" */)
}

